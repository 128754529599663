import React from 'react';
import { ThemeProvider } from 'styled-components';

import {
  BoardingPass,
  FeatureTiles as Tiles,
  IFeatureTile,
  Section,
  Heading,
  Copy,
  ContentFormatter,
  RichText
} from '@belong/ui-components';
import { THEME_VARIATIONS } from '@belong/themes';

import { ISectionFeatureTiles } from './model';

export const SectionFeatureTiles: React.FC<ISectionFeatureTiles> = ({
  id,
  identifier,
  content,
  featureTiles,
  heading,
  description,
  sectionStyles,
  footerContent,
  variant,
  clippingPosition,
  clippingSize,
  featuredImage,
  ctaLink,
  orderedList
}: ISectionFeatureTiles) => {
  const newTiles: IFeatureTile[] = featureTiles.map(tile => ({ ...tile, key: tile.id }));

  const currentTheme = sectionStyles?.themeVariation && THEME_VARIATIONS[sectionStyles.themeVariation];

  return (
    <ThemeProvider
      theme={theme => ({
        ...theme,
        ...currentTheme
      })}
    >
      <Section data-contentid={id} data-identifier={identifier} data-testid="section-feature-tiles" {...sectionStyles}>
        <ContentFormatter alignment="center">
          {heading || description ? (
            <>
              {heading && <Heading>{heading}</Heading>}
              {description && <Copy>{description}</Copy>}
            </>
          ) : (
            content && <RichText html={content} />
          )}
        </ContentFormatter>
        {variant === 'boardingPass' ? (
          <BoardingPass
            clippingTile={{ ...newTiles[0], ctaLink }}
            tiles={newTiles.slice(1)}
            clippingPosition={clippingPosition}
            clippingSize={clippingSize}
            detailesBannerImage={featuredImage}
          />
        ) : (
          <Tiles featureTiles={newTiles} orderedList={orderedList || false} />
        )}
        {footerContent && <RichText html={footerContent} alignment="center" />}
      </Section>
    </ThemeProvider>
  );
};

export default SectionFeatureTiles;
